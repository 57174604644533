<template>

<app-content :loading="is.loading">

	<app-content-head :title="title" back="Convention.Badges.Groups">
		
		<template v-if="$authorised('con/badges/access', 'write')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />
		<app-content-head-actions-item v-if="!isNew" :disabled="isNew" :danger="true" icon="delete" text="Delete" v-on:click="onDeleteClick" />

		</template>

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised">

		<app-content-box :readonly="$authorised('con/badges/access', 'read')">

			<app-input-text label="Name" v-model="model.name" :validation="$v.model.name" placeholder="Enter name" :maxlength="32" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/badges/access', 'read'),
			model: {
				id: false,
				name: ''
			}
		}

	},

	validations: {
		model: {
			name: {
				required,
				maxLength: maxLength(128)
			}
		}
	}

}

</script>

<style scoped>

</style>